var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      directives: [{ name: "drag", rawName: "v-drag" }],
      attrs: {
        title: "新增-费用细类",
        visible: _vm.dialogFormVisible,
        width: "800px",
        "close-on-click-modal": false,
        "close-on-press-escape": false,
        "append-to-body": "",
        "before-close": _vm.cancel,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogFormVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "content" },
        [
          _c(
            "el-form",
            {
              ref: "form",
              staticClass: "form",
              attrs: { model: _vm.form, rules: _vm.rules },
            },
            [
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("费用类型"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "feeTypeId" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "100%" },
                          attrs: { placeholder: "请选择费用类型" },
                          model: {
                            value: _vm.form.feeTypeId,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "feeTypeId", $$v)
                            },
                            expression: "form.feeTypeId",
                          },
                        },
                        _vm._l(_vm.feeTypeOptions, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("代码"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "feeCode" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入费用代码" },
                        model: {
                          value: _vm.form.feeCode,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "feeCode", $$v)
                          },
                          expression: "form.feeCode",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("名称"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "feeName" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入费用名称" },
                        model: {
                          value: _vm.form.feeName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "feeName", $$v)
                          },
                          expression: "form.feeName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item numberRange" },
                [
                  _c("label", { staticClass: "lable" }, [
                    _c("span", { staticClass: "red" }, [_vm._v("* ")]),
                    _vm._v("费用限额"),
                  ]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "expenseQuota" } },
                    [
                      _c("number-range", {
                        attrs: { min: 0, max: 999999 },
                        model: {
                          value: _vm.form.expenseQuota,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "expenseQuota", $$v)
                          },
                          expression: "form.expenseQuota",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [_vm._v("计算维度")]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "unit" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticStyle: { width: "320px" },
                          attrs: {
                            filterable: "",
                            placeholder: "请选择计算维度",
                          },
                          model: {
                            value: _vm.form.unit,
                            callback: function ($$v) {
                              _vm.$set(_vm.form, "unit", $$v)
                            },
                            expression: "form.unit",
                          },
                        },
                        _vm._l(_vm.config.unitOption, function (item) {
                          return _c("el-option", {
                            key: item.value,
                            attrs: { label: item.label, value: item.value },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "form-item" },
                [
                  _c("label", { staticClass: "lable" }, [_vm._v("费用介绍")]),
                  _c(
                    "el-form-item",
                    { attrs: { prop: "description" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入费用介绍" },
                        model: {
                          value: _vm.form.description,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "description", $$v)
                          },
                          expression: "form.description",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "span",
        { staticClass: "footers", attrs: { slot: "footer" }, slot: "footer" },
        [
          _c(
            "div",
            { staticClass: "cancel flex_c_c", on: { click: _vm.cancel } },
            [_vm._v("取 消")]
          ),
          _c(
            "div",
            {
              staticClass: "ok flex_c_c",
              on: {
                click: function ($event) {
                  return _vm.ok("form")
                },
              },
            },
            [_vm._v("确 定")]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }