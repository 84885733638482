<template>
  <el-dialog
    v-drag
    title="新增-费用细类"
    :visible.sync="dialogFormVisible"
    width="800px"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    append-to-body
    :before-close="cancel"
  >
    <div class="content">
      <!-- <base-tab :tabItems="tabItems" @tabChange="tabChange"></base-tab> -->
      <el-form ref="form" class="form" :model="form" :rules="rules">
        <!-- <div v-if="isActived === 0" class="form-item">
          <label class="lable"><span class="red">* </span>代码</label>
          <el-form-item prop="code">
            <el-input
              v-model="form.code"
              placeholder="请输入费用类型代码"
            ></el-input>
          </el-form-item>
        </div>
        <div v-if="isActived === 0" class="form-item">
          <label class="lable"><span class="red">* </span>名称</label>
          <el-form-item prop="name">
            <el-input
              v-model="form.name"
              placeholder="请输入费用类型名称"
            ></el-input>
          </el-form-item>
        </div>
        <div v-if="isActived === 0" class="form-item">
          <label class="lable"><span class="red">* </span>费用归类</label>
          <el-form-item prop="classification">
            <el-select
              v-model="form.classification"
              style="width: 100%"
              placeholder="请选择"
            >
              <el-option
                v-for="item in classification"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div> -->
        <div class="form-item">
          <label class="lable"><span class="red">* </span>费用类型</label>
          <el-form-item prop="feeTypeId">
            <el-select
              v-model="form.feeTypeId"
              style="width: 100%"
              placeholder="请选择费用类型"
            >
              <el-option
                v-for="item in feeTypeOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>代码</label>
          <el-form-item prop="feeCode">
            <el-input
              v-model="form.feeCode"
              placeholder="请输入费用代码"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable"><span class="red">* </span>名称</label>
          <el-form-item prop="feeName">
            <el-input
              v-model="form.feeName"
              placeholder="请输入费用名称"
            ></el-input>
          </el-form-item>
        </div>
        <div class="form-item numberRange">
          <label class="lable"><span class="red">* </span>费用限额</label>
          <el-form-item prop="expenseQuota">
            <number-range
              v-model="form.expenseQuota"
              :min="0"
              :max="999999"
            >
            </number-range>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable">计算维度</label>
          <el-form-item prop="unit">
            <el-select
              v-model="form.unit"
              filterable
              style="width: 320px"
              placeholder="请选择计算维度"
            >
              <el-option
                v-for="item in config.unitOption"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div class="form-item">
          <label class="lable">费用介绍</label>
          <el-form-item prop="description">
            <el-input
              v-model="form.description"
              placeholder="请输入费用介绍"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="footers">
      <div class="cancel flex_c_c" @click="cancel">取 消</div>
      <div class="ok flex_c_c" @click="ok('form')">确 定</div>
    </span>
  </el-dialog>
</template>
<script>
// const BaseTab = () => import('@/components/tab/BaseTab')
const NumberRange = () => import('@/components/base/NumberRange')
// import { getFeeTypeList, insertFeeType, insertFeeName } from '@/api/fee'
import { getFeeTypeList, insertFeeName } from '@/api/fee'
import config from '@/utils/config'
export default {
  components: { NumberRange },
  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      config,
      feeTypeOptions: [],
      classification: [
        { value: 0, label: '订单费用' },
        { value: 1, label: '提单费用' },
        { value: 2, label: '杂费' }
      ],
      tabItems: [
        {
          id: 0,
          name: '费用大类'
        },
        {
          id: 1,
          name: '费用细类'
        }
      ],
      form: {
        code: '',
        name: '',
        classification: '',
        feeTypeId: '',
        feeCode: '',
        feeName: '',
        unit: '',
        description: '',
        expenseQuota: 0
      },
      rules: {
        code: [{ required: true, message: '请输入费用类型代码', trigger: 'change' }],
        name: [{ required: true, message: '请输入费用类型名称', trigger: 'change' }],
        classification: [{ required: true, message: '请选择费用归类', trigger: 'change' }],
        feeTypeId: [{ required: true, message: '请选择费用类型', trigger: 'change' }],
        feeCode: [{ required: true, message: '请输入费用代码', trigger: 'blur' }],
        feeName: [{ required: true, message: '请输入费用名称', trigger: 'blur' }]
        // unit: [{ required: true, message: '请输入计算维度', trigger: 'change' }],
        // description: [{ required: true, message: '请输入费用介绍', trigger: 'change' }]
      },
      isActived: 0
    }
  },
  computed: {},
  watch: {
    dialogFormVisible(val) {
      if (val) {
        this.$refs.form?.resetFields()
        this.getList()
      }
    }
  },
  created() {
    console.log(this.$store.getters['user/userInfo'], 'userinfo')
  },
  methods: {
    tabChange(item) {
      this.isActived = item.id
    },
    cancel() {
      this.$refs['form'].resetFields()
      this.$emit('cancel')
    },
    cancel2() {
      this.dialogVisible2 = false
    },
    add() {
      this.dialogVisible2 = true
    },
    ok(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
            // if (this.isActived === 0) {
            //     insertFeeType({
            //         ...this.form,
            //         isActived: true
            //     }).then(res => {
            //         if (res.success) {
            //             this.$message.success('新增成功')
            //             this.$emit('ok')
            //         } else {
            //             this.$message.error(res.errorMessage)
            //         }
            //     })
            // } else {
            insertFeeName({
                ...this.form,
                isActived: true,
                isSendWay: false,
                expenseQuotaMin: this.form.expenseQuota[0],
                expenseQuotaMax: this.form.expenseQuota[1]
            }).then(res => {
                if (res.success) {
                    this.$message.success('新增成功')
                    this.$emit('ok')
                    this.$refs[formName].resetFields()
                } else {
                    this.$message.error(res.errorMessage)
                }
            })
            // }
        }
      })
    },
    async getList() {
      try {
        const res = await getFeeTypeList()
        if (res.success) {
          const arr = []
          res.data &&
            res.data.map((item) => {
              arr.push({
                value: item.id,
                label: item.name
              })
            })
          this.feeTypeOptions = arr
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  .form {
    display: flex;
    width: calc(100% - 80px);
    height: auto;
    flex-wrap: wrap;
    margin: 40px auto 0;
  }
  .form-item {
    width: calc(50% - 20px);
    position: relative;
    .lable {
      width: 100%;
      height: 19px;
      font-size: 14px;
      font-weight: 400;
      color: #666666;
      line-height: 20px;
      margin-bottom: 5px;
    }
    .red {
      color: rgba(255, 116, 68, 1);
    }
    &:nth-child(2n) {
      margin-left: 40px;
    }
    &:deep(.el-form-item__content) {
      line-height: normal;
    }
  }
}
.numberRange:deep(.el-input__inner) {
  border: 0;
  height: 38px;
  line-height: 38px;
}
.footers {
  display: flex;
  justify-content: center;
  .cancel {
    width: 120px;
    height: 40px;
    background: #EEEEEE;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #999999;
    margin-right: 16px;
    cursor: pointer;
  }
  .ok {
    width: 120px;
    height: 40px;
    background: #2395f3;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    cursor: pointer;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
/deep/.el-form--label-top .el-form-item__label {
  padding: 0;
}
</style>
