<template>
  <div class="view-contanier">
    <div class="opt">
      <div v-permissson="'add'" class="btn" @click="add">
        <svg-icon className="icon" iconClass="add" />新增
      </div>
      <BaseFilter :isActive="isActive" :width="504" @sendFilter="sendFilter" @reset="reset">
        <div class="filter">
          <el-form ref="form" :model="filterList" label-width="104px">
            <el-form-item label="费用名称/代码">
              <el-input
                v-model="filterList.search"
                placeholder="请输入费用名称/代码"
                style="width:320px;"
              ></el-input>
            </el-form-item>
            <el-form-item label="状态">
              <el-select
                v-model="filterList.isActived"
                placeholder="请选择状态"
                style="width:320px;"
              >
                <el-option
                  v-for="item in activeOption"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </el-form-item>
          </el-form>
        </div>
      </BaseFilter>
    </div>
    <div class="data">
      <BaseTable
        v-if="height !== 0"
        :height="height"
        :tableData="tableData"
        :pageIndex="pager.pageIndex"
        :pageSize="pager.pageSize"
        :loading="loading"
        :isPager="true"
        @handlePageChange="handlePageChange"
        @sortChange="sortChange"
      >
        <template slot="name" slot-scope="row">
          <el-input
            v-if="row.scope.isEdit"
            v-model="row.scope.name"
            @blur="nameBlur(row.scope)"
          ></el-input>
          <div v-else>
            {{ row.scope.name }}
            <img
              class="edit_name"
              src="@/assets/images/const/edit.png"
              @click="edit(row.scope)"
            />
          </div>
        </template>
        <template slot="classification" slot-scope="row">
          <div v-if="row.scope.classification === 0">订单费用</div>
          <div v-else-if="row.scope.classification === 1">提单费用</div>
          <div v-else-if="row.scope.classification === 2">杂费</div>
        </template>
        <template slot="isMondary" slot-scope="row">
          <div :class="row.scope.isMondary ? '' : 'grey'">{{ row.scope.isMondary ? "是" : "否" }}</div>
        </template>
        <template slot="isSendWay" slot-scope="row">
          <div :class="row.scope.isSendWay ? '' : 'grey'">{{ row.scope.isSendWay ? "是" : "否" }}</div>
        </template>
        <template slot="isActived" slot-scope="row">
          <div class="typebox">
            <div :class="row.scope.isActived ? 'bod' : 'bod red'"></div>
            <div>{{ row.scope.isActived ? "启用" : "停用" }}</div>
          </div>
        </template>
        <template slot="opt" slot-scope="row">
          <div class="tableopt">
            <div>
              <el-switch
                v-model="row.scope.isActived"
                v-permissson="'able'"
                active-color="#2395F3"
                inactive-color="#CDCDCD"
                :disabled="row.scope.isMondary"
                @change="changeStatus(row.scope)"
              >
              </el-switch
              ><span
                class="status"
                :style="{
                  color: row.scope.isActived ? '#2395F3' : '#999999',
                }"
              >{{ row.scope.isActived ? "启用" : "停用" }}</span
              >
            </div>
            <span v-if="!row.scope.isUsed" v-permissson="'delete'" style="cursor: pointer" @click="deleteRow(row.scope)">删除</span>
          </div>
        </template>
      </BaseTable>
    </div>
    <add-fee-dialog :dialogFormVisible="dialogFormVisible" @ok="ok" @cancel="cancel"></add-fee-dialog>
  </div>
</template>

<script>
const BaseTable = () => import('@/components/table/BaseTable')
const BaseFilter = () => import('@/components/filter/BaseFilter')

import { getFeeNamePageList, switchFeeName, deleteFeeName, updateFeeName } from '@/api/fee'
import AddFeeDialog from './components/AddFeeDialog.vue'
import { myMixins } from '@/mixins'
import config from '@/utils/config'
export default {
  name: 'FeeCode',
  components: {
    BaseTable,
    BaseFilter,
    AddFeeDialog
  },
  mixins: [myMixins],
  data() {
     return {
      config,
      tableData: {
        fileds: [
          {
            filed: 'name',
            label: '费用细类名称',
            width: '220',
            isSort: true, // 是否支持过滤
            fixed: 'left', // 是否固定 true, left, right
            isCustom: true // 是否自定义列显示
          },
          {
            filed: 'code',
            label: '费用细类代码',
            width: '160',
            isSort: true
          },
          {
            filed: 'feeTypeName',
            label: '费用大类名称',
            width: '160',
            isSort: true
          },
          {
            filed: 'feeTypeCode',
            label: '费用大类代码',
            width: '160',
            isSort: true
          },
          {
            filed: 'expenseQuotaMin',
            label: '费用限额',
            width: '160',
            formatter: (row) => {
              if (row.expenseQuotaMin !== null && row.expenseQuotaMax !== null) {
                return row.expenseQuotaMin + '-' + row.expenseQuotaMax
              } else {
                return '0-0'
              }
            }
          },
          {
            filed: 'classification',
            label: '费用归类',
            width: '140',
            isCustom: true
          },
          {
            filed: 'isMondary',
            label: '系统预设',
            width: '120',
            isCustom: true
          },
          {
            filed: 'isSendWay',
            label: '选择派送方式',
            width: '120',
            isCustom: true
          },
          {
            filed: 'unit',
            label: '计算维度',
            width: '120',
            formatter: (row) => {
              return config.unitOption.find(a => a.value === row.unit)?.label
            }
          },
          {
            filed: 'description',
            label: '费用介绍',
            width: '286'
          },
          {
            filed: 'isActived',
            label: '状态',
            width: '120',
            isCustom: true,
            isSort: true
          },
          {
            filed: 'updatedName',
            label: '修改人',
            width: '120'
          },
          {
            filed: 'updatedOn',
            label: '修改时间',
            width: '150',
            formatter: (row) =>
              row.updatedOn ? row.updatedOn.slice(0, row.updatedOn.length - 3) : '',
            isSort: true
          },
          {
            filed: 'createdName',
            label: '创建人',
            width: '120'
          },
          {
            filed: 'createdOn',
            label: '创建时间',
            width: '150',
            formatter: (row) =>
              row.createdOn ? row.createdOn.slice(0, row.createdOn.length - 3) : '',
            isSort: true
          },
          {
            filed: 'opt',
            label: '操作',
            width: '180',
            isCustom: true,
            fixed: 'right'
          }
        ],
        columns: [],
        total: 0
      },
      loading: false,
      pager: {
        pageIndex: 1,
        pageSize: 20
      },
      height: 0,
      filterList: {
        search: '',
        isActived: ''
      },
      isActive: false,
      dialogFormVisible: false,
      editData: {},
      drawer: false,
      drawer1: false,
      activeOption: [
        {
          value: true,
          label: '启用'
        },
        {
          value: false,
          label: '停用'
        }
      ],
      orderObj: {}
    }
  },
  created() {
    this.initList()
  },
  mounted() {
    this.height = document.querySelector('.data').offsetHeight - 43
  },
  methods: {
    ok() {
      this.cancel()
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 20
      }
      this.getList({
        pageIndex: 1,
        pageSize: 20
      })
    },
    add() {
      this.dialogFormVisible = true
    },
    cancel() {
      this.editData = {}
      this.dialogFormVisible = false
    },
    handlePageChange(pager) {
      this.pagerConfig = {
        ...this.pagerConfig,
        ...pager
      }
      const params = {
        ...this.pagerConfig,
        ...pager
      }
      this.getList(params)
    },
    sortChange(orderObj) {
      if (orderObj.order) {
        this.orderObj = {
          sortField: orderObj.prop,
          sortBy: orderObj.order === 'asc' ? 0 : 1
        }
      } else {
        this.orderObj = {}
      }
      this.getList(this.pagerConfig)
    },
    getList(params) {
      this.loading = true
      try {
        params = {
          ...params,
          queryParam: {
            ...this.orderObj,
            ...this.filterList,
            isVisible: true
          }
        }
        getFeeNamePageList(params).then((res) => {
          if (res.success) {
            this.loading = false
            this.tableData.columns = res.data.list.map(a => {
              return {
                ...a,
                isEdit: false
              }
            })
            this.tableData.total = res.data.total
          } else {
            this.loading = false
            this.$message.error(res.errorMessage)
          }
        })
      } catch (error) {
        this.loading = false
        console.error(error)
      }
    },
    initList() {
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 20
      }
      this.getList({
        pageIndex: 1,
        pageSize: 20
      })
    },
    sendFilter() {
      if (this.filterList.search !== '' || this.filterList.isActived !== '') {
        this.isActive = true
      } else {
        this.isActive = false
      }
      this.pagerConfig = {
        pageIndex: 1,
        pageSize: 20
      }
      this.getList({
        pageIndex: 1,
        pageSize: 20
      })
    },
    reset() {
      this.filterList = {
        search: '',
        isActived: ''
      }
      this.isActive = false
    },
    changeStatus(row) {
      if (row.isMondary) {
        this.$message.error('系统预设不可操作')
        return
      }
      switchFeeName({ id: row.id }).then(res => {
        if (res.success) {
          this.$message.success('修改成功')
          this.getList(this.pagerConfig)
        } else {
          this.$message.error(res.errorMessage)
        }
      })
    },
    deleteRow(row) {
      if (row.isMondary) {
        this.$message.error('系统预设不可操作')
        return
      }
      this.$confirm('此操作将删除该费用细类, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.loading = true
          deleteFeeName({
            feeNameId: row.id
          }).then((res) => {
            this.loading = false
            if (res.success) {
              this.$message.success('删除成功')
              this.initList()
            } else {
              this.$message.error(res.errorMessage)
            }
          })
        })
        .catch(() => {})
    },
    edit(row) {
      row.isEdit = true
    },
    async nameBlur(row) {
      try {
        const params = {
          feeNameId: row.id,
          name: row.name
        }
        const res = await updateFeeName(params)
        if (res.success) {
          row.isEdit = false
          this.$message.success(res.errorMessage)
        } else {
          this.$message.error(res.errorMessage)
        }
      } catch (error) {
        console.error(error)
      }
    }
  }
}
</script>

<style lang="less" scoped>
.view-contanier {
  width: 100%;
  padding: 0 10px;
  height: 100%;
  background: #fff;
  box-sizing: border-box;
  overflow: auto;
  .opt {
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  .btn {
    width: 80px;
    height: 30px;
    background: #2395F3;
    border-radius: 4px;
    border: 1px solid #eeeeee;
    opacity: 1;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    line-height: 30px;
    text-align: center;
    cursor: pointer;
    margin-right: 10px;
  }
  .icon {
    font-size: 20px;
    vertical-align: text-top;
  }
  .data {
    width: 100%;
    height: calc(100% - 50px);
    & .fTable {
      border-top: 1px solid #ebeef5;
      box-sizing: border-box;
    }
    .edit_name {
      cursor: pointer;
    }
  }
  .circle {
    width: 6px;
    height: 6px;
    opacity: 1;
    display: inline-block;
    border-radius: 50%;
    margin-right: 6px;
  }
  .tableopt {
    display: flex;
    justify-content: left;
    align-items: center;
    margin-left: 20px;
    span {
      margin-right: 20px;
      font-size: 14px;
      font-family: Microsoft YaHei-Regular, Microsoft YaHei;
      font-weight: 400;
      color: #2395f3;
      cursor: pointer;
    }
  }
  .grey {
    color: #CDCDCD;
  }
  .typebox {
    display: flex;
    justify-content: center;
    align-items: center;
    .bod {
      width: 6px;
      height: 6px;
      background: #35ba83;
      opacity: 1;
      border-radius: 50%;
      margin-right: 5px;
    }
    .red {
      width: 6px;
      height: 6px;
      background: #fe453d;
      opacity: 1;
    }
  }

  .status {
    width: 28px;
    height: 19px;
    font-size: 14px;
    font-family: Microsoft YaHei-Regular, Microsoft YaHei;
    font-weight: 400;
    color: #2395f3;
    line-height: 20px;
    margin-left: 5px;
    margin-right: 31px;
  }
  .table-opt:deep(.el-button) {
    padding: 0;
  }
}
</style>
