var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "view-contanier" },
    [
      _c(
        "div",
        { staticClass: "opt" },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "permissson",
                  rawName: "v-permissson",
                  value: "add",
                  expression: "'add'",
                },
              ],
              staticClass: "btn",
              on: { click: _vm.add },
            },
            [
              _c("svg-icon", {
                attrs: { className: "icon", iconClass: "add" },
              }),
              _vm._v("新增 "),
            ],
            1
          ),
          _c(
            "BaseFilter",
            {
              attrs: { isActive: _vm.isActive, width: 504 },
              on: { sendFilter: _vm.sendFilter, reset: _vm.reset },
            },
            [
              _c(
                "div",
                { staticClass: "filter" },
                [
                  _c(
                    "el-form",
                    {
                      ref: "form",
                      attrs: { model: _vm.filterList, "label-width": "104px" },
                    },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "费用名称/代码" } },
                        [
                          _c("el-input", {
                            staticStyle: { width: "320px" },
                            attrs: { placeholder: "请输入费用名称/代码" },
                            model: {
                              value: _vm.filterList.search,
                              callback: function ($$v) {
                                _vm.$set(_vm.filterList, "search", $$v)
                              },
                              expression: "filterList.search",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "状态" } },
                        [
                          _c(
                            "el-select",
                            {
                              staticStyle: { width: "320px" },
                              attrs: { placeholder: "请选择状态" },
                              model: {
                                value: _vm.filterList.isActived,
                                callback: function ($$v) {
                                  _vm.$set(_vm.filterList, "isActived", $$v)
                                },
                                expression: "filterList.isActived",
                              },
                            },
                            _vm._l(_vm.activeOption, function (item) {
                              return _c("el-option", {
                                key: item.value,
                                attrs: { label: item.label, value: item.value },
                              })
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "data" },
        [
          _vm.height !== 0
            ? _c("BaseTable", {
                attrs: {
                  height: _vm.height,
                  tableData: _vm.tableData,
                  pageIndex: _vm.pager.pageIndex,
                  pageSize: _vm.pager.pageSize,
                  loading: _vm.loading,
                  isPager: true,
                },
                on: {
                  handlePageChange: _vm.handlePageChange,
                  sortChange: _vm.sortChange,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "name",
                      fn: function (row) {
                        return [
                          row.scope.isEdit
                            ? _c("el-input", {
                                on: {
                                  blur: function ($event) {
                                    return _vm.nameBlur(row.scope)
                                  },
                                },
                                model: {
                                  value: row.scope.name,
                                  callback: function ($$v) {
                                    _vm.$set(row.scope, "name", $$v)
                                  },
                                  expression: "row.scope.name",
                                },
                              })
                            : _c("div", [
                                _vm._v(" " + _vm._s(row.scope.name) + " "),
                                _c("img", {
                                  staticClass: "edit_name",
                                  attrs: {
                                    src: require("@/assets/images/const/edit.png"),
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.edit(row.scope)
                                    },
                                  },
                                }),
                              ]),
                        ]
                      },
                    },
                    {
                      key: "classification",
                      fn: function (row) {
                        return [
                          row.scope.classification === 0
                            ? _c("div", [_vm._v("订单费用")])
                            : row.scope.classification === 1
                            ? _c("div", [_vm._v("提单费用")])
                            : row.scope.classification === 2
                            ? _c("div", [_vm._v("杂费")])
                            : _vm._e(),
                        ]
                      },
                    },
                    {
                      key: "isMondary",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            { class: row.scope.isMondary ? "" : "grey" },
                            [_vm._v(_vm._s(row.scope.isMondary ? "是" : "否"))]
                          ),
                        ]
                      },
                    },
                    {
                      key: "isSendWay",
                      fn: function (row) {
                        return [
                          _c(
                            "div",
                            { class: row.scope.isSendWay ? "" : "grey" },
                            [_vm._v(_vm._s(row.scope.isSendWay ? "是" : "否"))]
                          ),
                        ]
                      },
                    },
                    {
                      key: "isActived",
                      fn: function (row) {
                        return [
                          _c("div", { staticClass: "typebox" }, [
                            _c("div", {
                              class: row.scope.isActived ? "bod" : "bod red",
                            }),
                            _c("div", [
                              _vm._v(
                                _vm._s(row.scope.isActived ? "启用" : "停用")
                              ),
                            ]),
                          ]),
                        ]
                      },
                    },
                    {
                      key: "opt",
                      fn: function (row) {
                        return [
                          _c("div", { staticClass: "tableopt" }, [
                            _c(
                              "div",
                              [
                                _c("el-switch", {
                                  directives: [
                                    {
                                      name: "permissson",
                                      rawName: "v-permissson",
                                      value: "able",
                                      expression: "'able'",
                                    },
                                  ],
                                  attrs: {
                                    "active-color": "#2395F3",
                                    "inactive-color": "#CDCDCD",
                                    disabled: row.scope.isMondary,
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.changeStatus(row.scope)
                                    },
                                  },
                                  model: {
                                    value: row.scope.isActived,
                                    callback: function ($$v) {
                                      _vm.$set(row.scope, "isActived", $$v)
                                    },
                                    expression: "row.scope.isActived",
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass: "status",
                                    style: {
                                      color: row.scope.isActived
                                        ? "#2395F3"
                                        : "#999999",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        row.scope.isActived ? "启用" : "停用"
                                      )
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            !row.scope.isUsed
                              ? _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "permissson",
                                        rawName: "v-permissson",
                                        value: "delete",
                                        expression: "'delete'",
                                      },
                                    ],
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteRow(row.scope)
                                      },
                                    },
                                  },
                                  [_vm._v("删除")]
                                )
                              : _vm._e(),
                          ]),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  2573842452
                ),
              })
            : _vm._e(),
        ],
        1
      ),
      _c("add-fee-dialog", {
        attrs: { dialogFormVisible: _vm.dialogFormVisible },
        on: { ok: _vm.ok, cancel: _vm.cancel },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }